import { useState } from "react";
import contact from "../assets/contact.svg";
import emailjs from 'emailjs-com';
import toast from 'react-hot-toast';
import Heading from "../components/Heading";

const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);


    const onChangeHandler = (setState) => (e) => {
        setState(e.target.value)
    }

    const _submit = async (e) => {
        e.preventDefault();

        const user_id = "bLCOQLJsu97P_uiTm";
        const service_id = "service_nat3nd9"
        const template_id = "template_486egac";

        const emailData = {
            name: name,
            email: email,
            message: message
        }

        setLoading(true);
        await emailjs.send(service_id, template_id, emailData, user_id)
            .then((result) => {
                console.log(result.text);
                toast.success("Message send to Eden Green Eco Solutions team");
            }, (error) => {
                console.log(error.text);
                toast.error("Message not send successfully");
            });

        setName('');
        setEmail('');
        setMessage('');
        e.target.reset();
        setLoading(false);
    }

    const renderContactForm = () => (
        <form onSubmit={_submit} className="contact-form">
            <div className="input-field">
                <label htmlFor="name">Name</label>
                <input type="text" placeholder="Enter name" value={name} name="name" className="form-control" required
                    onChange={onChangeHandler(setName)} />
            </div>
            <div className="input-field">
                <label htmlFor="email">Email</label>
                <input type="email" placeholder="Enter email" value={email} name="email" className="form-control" required
                    onChange={onChangeHandler(setEmail)} />
            </div>
            <div className="input-field">
                <label htmlFor="message">Message</label>
                <textarea name="" id="" cols="10" rows="5" className="form-control" required
                    onChange={onChangeHandler(setMessage)} />
            </div>
            <button type="submit" className={`send-btn ${loading ? 'submit_loader' : ''}`} disabled={loading} >{loading ? "" : "Send Message"}</button>
        </form>
    )

    return (

        <>
            <Heading id="contact" text="Contact Us" />
            <div className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            {renderContactForm()}
                        </div>
                        <div className="col-md-6">
                            <h1>Address</h1>
                            <p>Apex Office Space 1 Water Vole Way, Balby, Doncaster, United Kingdom, DN4 5JP</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;