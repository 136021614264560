import bgImg from "../assets/bgImg.jpg";
import down from "../assets/down.gif";

function Homepage() {
  return (
    <div id="/" className="homepage">
      <img src={bgImg} alt="bgImg" />
      <div className="homepage-overlay">
        <div className="outer">
          <h1>Eden Green Eco Solutions</h1>
          <h2>We provide Plastic Recycling services</h2>
        </div>
      </div>

      <a href="#about">
        <img src={down} alt="down" />
      </a>
    </div>
  );
}

export default Homepage;
