
import brandLogo from '../assets/logo_white.png'

const TopNav = () => (
    <nav className="top">
        <div className="top_nav">

            <div className="top_nav-logo">
                <img src={brandLogo} alt="brandLogo" />
            </div>

            <div className="top_nav-navbar">
                <a className="navbar_item" href="#">Home</a>
                <a className="navbar_item" href="#about">About</a>
                <a className="navbar_item" href="#contact">Contact</a>
            </div>

        </div>
    </nav>
)


export default TopNav
