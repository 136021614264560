import img_1 from "../assets/img1.jpg";
import img_2 from "../assets/img2.jpg";
import img_3 from "../assets/img3.jpg";
import img_4 from "../assets/img4.jpg";

export const data = () => [
  {
    img: img_1,
    label: "We buy Plastic Scrap",
    info: `We have been in the business of buying and selling plastic and have extensive knowledge and experience 
        in international end markets. Our global team of experts operate across the world, making Eden Green Eco Solutions 
        a leading business when it comes to buying scrap plastic as well as selling plastic for recycling.`,
  },
  {
    img: img_2,
    label: "Plastic coloring",
    info: `Color enhances and adds value to custom engineered compounds formulated to meet specific physical requirements. 
        Color can also provide compounds with a cosmetic quality appearance or an eye catching special effect look.`,
  },
  {
    img: img_3,
    label: "Plastic Reprocessing",
    info: `Eden Green Eco Solutions reprocess sorted plastic waste products into new plastic feedstock for the plastics manufacturing industry. 
    It is a simple process using specialist machinery to grind different types of plastic into a product which is 
    easy to transport and handle`,
  },
  {
    img: img_4,
    label: "Plastic melting",
    info: `Plastic is an undeniably useful household material. Not only is it super strong, but it's also easy to 
    use in manufacturing.`,
  },
  // {
  //     img: img_2,
  //     label: "Metal Trading",
  //     info: `We buy and sell all ferrous and non-ferrous metal waste. Our customers
  //         include manufacturers, metal fabricators, domestic mills, plumbers, electricians,
  //         roofers and vehicle mechanics`
  // },
  // {
  //     img: img_6,
  //     label: "Metal Appearance",
  //     info: `We improve the performance and appearance of metal parts. Whether you're looking for quality
  //         anodizing specialities, coating, or another solution, our experienced team is here to help. `
  // },
  // {
  //     img: img_1,
  //     label: "We buy plastic scrap",
  //     info: `We have been in the business of buying and selling plastic and have extensive knowledge and experience
  //     in international end markets. When it comes to buying scrap plastic as well as selling plastic for recycling. `
  // },

  // {
  //     img: img_5,
  //     label: "Plastic melting",
  //     info: `Plastic is an undeniably useful household material. Not only is it super strong, but it's also easy to use
  //     in manufacturing.`
  // },
];
